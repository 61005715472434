import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { FunnelCalendarBooking } from '@quno/patient-journey/src/components/CalendarBooking/FunnelCalendarBooking';
import type { Package } from './BlockCard';
import type { BlockCalendarBookingFragment } from '../../generated/graphql-patient-journey';

export const BlockCalendarBooking = (
  props: BlockCalendarBookingFragment,
): JSX.Element | null => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const eventSetupId = updatedEntity.eventSetupId as string;
  const funnelId = updatedEntity.funnelId as string;
  const titleOverride = updatedEntity.titleOverride as string;
  const index = updatedEntity.package?.sys?.urn?.lastIndexOf('/') || -1;
  const contentfulPackageId = updatedEntity.package?.sys?.urn?.substring(
    index + 1,
  );
  const contentfulPackage = updatedEntity.package
    ? { sys: { id: contentfulPackageId } }
    : undefined;

  return (
    <FunnelCalendarBooking
      cid={props.sys.id}
      eventSetupId={eventSetupId}
      funnelId={funnelId}
      contentfulPackage={contentfulPackage as Package}
      titleOverride={titleOverride}
    />
  );
};
