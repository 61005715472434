import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { Cta } from '@quno/patient-journey/src/components/Cta/Cta';
import type { CtaFragment } from '../../generated/graphql-patient-journey';
import type { Package } from './BlockCard';

export const BlockCta = (props: CtaFragment): JSX.Element | null => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const { text, link } = updatedEntity;

  const { action, funnel, product } = updatedEntity;
  const funnelUrn = funnel?.sys.urn;

  let funnelId = null;
  if (['funnel', 'calendar'].includes(action || '') && funnelUrn) {
    const index = funnelUrn.lastIndexOf('/');
    funnelId = funnelUrn.substring(index + 1);
  }

  const packageUrl = product?.sys.urn;
  let contentfulPackageId = null;

  if (packageUrl) {
    const index = packageUrl.lastIndexOf('/');
    contentfulPackageId = packageUrl.substring(index + 1);
  }

  const contentfulPackage = contentfulPackageId
    ? { sys: { id: contentfulPackageId } }
    : undefined;

  return (
    <Cta
      action={action}
      funnelId={funnelId}
      {...updatedEntity}
      contentfulPackage={contentfulPackage as Package}
      text={text}
      link={link}
    />
  );
};
