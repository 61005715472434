import clsx from 'clsx';
import Image from 'next/image';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { backgroundColorClass } from '@quno/patient-journey/src/theme/backgroundColorClass';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import { getOpacity } from '@quno/patient-journey/src/utils/opacity';
import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import { Cta } from '@quno/patient-journey/src/components/Cta/Cta';
import styles from './HeroSimple.module.scss';
import type { CtaFragment } from '../../contentful/generated/graphql-patient-journey';
import type { Package } from '../../contentful/patient-journey/blocks/BlockCard';
import type { HeroProps } from './hero.config';

export type HeroSimpleProps = HeroProps & {
  ctas?: (CtaFragment & { funnelId?: string })[];
};

export const HeroSimple = ({
  cid,
  text,
  image,
  imageDetailPosition,
  centerContent,
  flipContent,
  backgroundColor = 'default',
  cardOnMobile,
  backgroundColorOverwrite,
  backgroundColorOpacity,
  ctas,
  imageBorderRadius,
  fontColorOverride,
}: HeroSimpleProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });
  const opacity = getOpacity(backgroundColorOpacity!);

  const textSection = (
    <div
      key="text"
      className={clsx(
        styles.textSection,
        centerContent && styles.centerContent,
      )}
      style={{ color: fontColorOverride || 'black' }}
      {...inspectorProps({ fieldId: 'textContent' })}
    >
      <RichText
        fields={text}
        customStyle={styles.customRichTextStyles}
        style={
          {
            color: fontColorOverride,
            '--list-style-color': fontColorOverride,
          } as React.CSSProperties
        }
      />

      {!!ctas?.length && (
        <div
          className={clsx(styles.ctas, centerContent && styles.centerContent)}
        >
          {ctas.map((cta, i) => {
            const { funnel, product, action } = cta;
            const funnelUrn = funnel?.sys.urn;

            let funnelId = cta.funnelId || null;

            if (['funnel', 'calendar'].includes(action || '') && funnelUrn) {
              const index = funnelUrn.lastIndexOf('/');
              funnelId = funnelUrn.substring(index + 1);
            }

            const packageUrl = product?.sys.urn;
            let contentfulPackageId = null;

            if (action === 'calendar' && packageUrl) {
              const index = packageUrl.lastIndexOf('/');
              contentfulPackageId = packageUrl.substring(index + 1);
            }

            const contentfulPackage = contentfulPackageId
              ? { sys: { id: contentfulPackageId } }
              : undefined;

            return (
              <Cta
                key={i}
                {...cta}
                contentfulPackage={contentfulPackage as Package}
                funnelId={funnelId}
              />
            );
          })}
        </div>
      )}
    </div>
  );

  const imageSection = image && (
    <div key="image" className={styles.imageSection}>
      <Image
        {...image}
        loader={createCloudimgLoader()}
        {...inspectorProps({ fieldId: 'image' })}
        style={{
          objectPosition: imageDetailPosition || 'center',
          borderRadius: imageBorderRadius,
        }}
      />
    </div>
  );

  const sections = [textSection, imageSection];

  return (
    <div
      className={clsx(
        styles.container,
        cardOnMobile && styles.cardOnMobile,
        backgroundColorClass(backgroundColor),
      )}
      style={{
        backgroundColor: backgroundColorOverwrite || undefined,
        opacity: opacity || '1',
      }}
    >
      <div
        className={clsx(styles.wrapper, imageSection && styles.heroWithImage)}
      >
        {!flipContent ? sections : sections.reverse()}
      </div>
    </div>
  );
};
